












import {defineComponent, PropType} from '@vue/composition-api';
import {useVModel} from '@vueuse/core';
import TaskState from '@/task-states/models/TaskState';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default defineComponent({
  name: 'MultipleChoiceUiComponent',
  components: {StembleLatex},
  props: {
    options: {
      type: Array as PropType<{key: string; label: string}[]>,
      default: () => [],
    },
    selectedOption: {},
    taskState: {
      type: Object as PropType<TaskState | null>,
      default: null,
    },
  },
  emits: {
    'update:selectedOption': (option: string) => {},
  },
  setup(props) {
    const modelValue = useVModel(props, 'selectedOption');

    return {modelValue};
  },
});
